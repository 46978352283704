
import { OfficeType } from "@/api/types";
import Card from "@/components/common/Card.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import DelegatesList from "@/components/delegates/DelegatesList.vue";
import CompanyPrice from "@/components/events/CompanyPrice.vue";
import Spinner from "@/components/shared/Spinner.vue";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";

import { namespace } from "vuex-class";

const Auth = namespace("auth");
const Events = namespace("events");
const Company = namespace("company");
const Instructors = namespace("instructors");

@Component({
  components: {
    DelegatesList,
    Card,
    PageTitle,
    ValidationObserver,
    ValidationProvider,
    CompanyPrice,
    Spinner
  }
})
export default class CreateEventPage extends Vue {
  OCCUPATIONAL_QUALIFICATION_ID = 8;

  currentDate = moment().format("YYYY-MM-DD");
  nextDay = moment()
    .add(1, "days")
    .format("YYYY-MM-DD");
  selectedDelegates: any[] = [];
  bookingTypes = [];
  courseDurationId = 1;
  courseOverview: Array<{ title: string; content: any }> = [];
  isSingleSelect = false;
  addressPrepopulate = "";
  accomodationRules = "";
  tableHeader = {
    title: "Assign delegates",
    icon: "mdi-account"
  };
  menu2 = false;
  menu3 = false;
  menu4 = false;
  menu = false;
  buttonsArray = [
    {
      text: "Back",
      icon: "mdi-chevron-left",
      color: "white",
      action: "back",
      disabled: false
    },
    {
      text: "Save",
      icon: "mdi-pencil-box-outline",
      color: "dark",
      action: "save",
      disabled: false
    }
  ];
  durationVal: any = 0;
  selectedCourseCatId: any = null;
  headersTitle = [{ icon: "mdi-file-edit", header: "Booking Request" }];
  loading = false;

  @Auth.Getter("getUser")
  getUser!: object | null;
  @Events.Action("getCoursesList")
  private getCoursesList!: () => Promise<any>;
  @Events.Action("getAuditsList")
  private getAuditsList!: () => Promise<any>;
  @Events.Action("getOnlineCoursesList")
  private getOnlineCoursesList!: () => Promise<any>;
  @Events.Action("getEventTypes")
  private getEventTypes!: () => Promise<any>;
  @Events.Action("getOfficeAddress")
  private getOfficeAddress!: () => Promise<any>;
  @Events.Action("getOfficeTypes")
  private getOfficeTypes!: () => Promise<any>;
  @Events.Action("getCoursesDurations")
  private getCoursesDurations!: () => Promise<any>;
  @Events.Action("getCoursesInfo")
  private getCoursesInfo!: (id: string) => Promise<any>;
  @Events.Action("getAuditInfo")
  private getAuditInfo!: (id: string) => Promise<any>;
  @Events.Action("getOnlineCoursesInfo")
  private getOnlineCoursesInfo!: (id: string) => Promise<any>;
  @Events.Action("createEvent")
  createEvent!: (params: any) => Promise<void>;
  @Instructors.Action("getInstructorslistByCourseID")
  private getInstructorslistByCourseID!: ({
    id,
    active
  }: {
    id: number;
    active: string | number;
  }) => Promise<any>;
  @Instructors.Action("getInstructorslistByAuditID")
  private getInstructorslistByAuditID!: ({
    id,
    active
  }: {
    id: number;
    active: string | number;
  }) => Promise<any>;
  @Instructors.Action("getInstructorslistByOnlineCourseID")
  private getInstructorslistByOnlineCourseID!: ({
    id,
    active
  }: {
    id: number;
    active: string | number;
  }) => Promise<any>;
  @Company.Action("getListCompanyWithPagination")
  private getListCompanyWithPagination!: () => Promise<any>;

  @Events.Mutation("startFetch")
  eventStartFetch!: () => void;
  @Events.Mutation("setEventType")
  setEventType!: (type: any) => void;
  @Events.Mutation("setEventEventableId")
  setEventEventableId!: () => void;
  @Company.Mutation("startFetch")
  companyStartFetch!: () => void;
  @Instructors.Mutation("setInstructors")
  setInstructors!: (payload: []) => void;

  @Events.State("courses") courses: any;
  @Events.State("audits") audits: any;
  @Events.State("onlineCourses") onlineCourses: any;
  @Events.State("officeTypes") officeTypes: any;
  @Events.State("event") event: any;
  @Events.State("durations") durations: any;
  @Events.State("coursesInfo") coursesInfo: any;
  @Instructors.State("instructors") instructors: any;

  get isTransportDisabled() {
    return !this.addressRules;
  }
  get addressRules() {
    const index = this.officeTypes.findIndex(
      (officeType: OfficeType) => officeType.id === this.event.officeTypeId
    );
    if (index !== -1) {
      const selectedOfficeType: OfficeType = this.officeTypes[index];
      if (selectedOfficeType) {
        if (selectedOfficeType.id === 2) {
          this.accomodationRules = "required";
          this.event.accommodation = 0;
        }
        this.accomodationRules = "";
        return selectedOfficeType.validateAddress ? "required" : "";
      }
    }
    return "";
  }
  get checkUser() {
    const currentUser: any = this.getUser;
    const isManager =
      currentUser && currentUser.permissions
        ? Boolean(
            currentUser.permissions.find(
              (permission: string) => permission === "create_role"
            )
          )
        : false;
    return isManager;
  }
  get isOccupationalQualification() {
    return this.coursesInfo?.category?.id == this.OCCUPATIONAL_QUALIFICATION_ID;
  }

  async created() {
    this.loading = true;
    try {
      this.eventStartFetch();
      this.companyStartFetch();
      await this.getCoursesList();
      await this.getOfficeTypes();
      await this.getCoursesDurations();
      await this.getListCompanyWithPagination();
      this.bookingTypes = await this.getEventTypes();
      this.setEventType(this.bookingTypes[0]);
      const loadedAddress = await this.getOfficeAddress();
      if (loadedAddress.data.length > 0) {
        this.addressPrepopulate = loadedAddress.data[0].address;
        this.event.address = this.addressPrepopulate;
      }
    } catch (e) {
      await this.$error(e.errors || { err: e.result.message });
    }
    if (this.$route.params.date) {
      this.event.eventStartDate = moment(this.$route.params.date).format(
        "YYYY-MM-DD"
      );
    }
    this.loading = false;
  }

  @Watch("event.eventType")
  async onSelectBookingType(type: any) {
    if (type) {
      if (type.name === "Audit") {
        await this.getAuditsList().catch(e => {
          this.$error({ error: e });
        });
      }
      if (type.name === "Online Course") {
        await this.getOnlineCoursesList().catch(e => {
          this.$error({ error: e });
        });
      }

      if (type.id === 2) this.event.officeTypeId = 2;
      this.setInstructors([]);
      this.setEventEventableId();
    }
  }
  @Watch("event.eventableId")
  async onSelectCourse(id: number) {
    this.setInstructors([]);
    if (id && this.event.eventType.name === "Course") {
      try {
        await this.getInstructorslistByCourseID({ id, active: 1 });
        await this.getCoursesInfo(id.toString());
        const course = this.coursesInfo;
        this.courseDurationId = course.duration.id;
        const durationName =
          this.coursesInfo.category.id === 3 ? "" : course.duration;

        this.courseOverview = [
          { title: "Name", content: course.name },
          {
            title: "Duration",
            content: durationName.name
          },
          { title: "Certificate", content: course.courseType },
          {
            title: "Cost Per Day",
            content: `${new Intl.NumberFormat("en", {
              style: "currency",
              currency: "ZAR",
              minimumFractionDigits: 2
            })
              .format(this.coursesInfo.price || 0)
              .substr(2)}`
          },
          {
            title: course.dol ? "DOL #" : "",
            content: course.dol ? course.dol : ""
          }
        ];
        if (this.coursesInfo.category.id === 3) {
          this.durationVal = course.duration;
        }
      } catch (e) {
        await this.$error(e);
      }
    }

    if (id && this.event.eventType.name === "Audit") {
      try {
        await this.getInstructorslistByAuditID({ id, active: 1 });
        await this.getAuditInfo(id.toString());
        this.courseDurationId = this.coursesInfo.duration;
      } catch (e) {
        await this.$error(e);
      }
    }

    if (id && this.event.eventType.name === "Online Course") {
      try {
        await this.getInstructorslistByOnlineCourseID({ id, active: 1 });
        await this.getOnlineCoursesInfo(id.toString());

        const course = this.coursesInfo;

        this.courseDurationId = course.duration.id;
        const durationName =
          this.coursesInfo.category.id === 3 ? "" : course.duration;
        this.courseOverview = [
          { title: "Name", content: course.name },
          {
            title: "Duration",
            content: durationName.name
          },
          { title: "Certificate", content: course.type.name },
          {
            title: "Cost Per Day",
            content: `${new Intl.NumberFormat("en", {
              style: "currency",
              currency: "ZAR",
              minimumFractionDigits: 2
            })
              .format(this.coursesInfo.price || 0)
              .substr(2)}`
          },
          {
            title: "DOL #",
            content: course.dol != "null" ? course.dol : "- "
          }
        ];
        if (this.coursesInfo.category.id === 3) {
          this.durationVal = course.duration;
        }
      } catch (e) {
        await this.$error(e);
      }
    }
  }
  @Watch("event.officeTypeId")
  async onOfficeTypeChange(value: any) {
    if (value === 1) {
      this.event.address = this.addressPrepopulate;
    } else {
      this.event.address = "";
    }
  }
  @Watch("event.company", { deep: true })
  editSumCard() {
    if (this.event.company.length) {
      this.updPrice();
    }
    const a = this.courseOverview;
    if (a && a[3]?.content) {
      a[3].content = `${new Intl.NumberFormat("en", {
        style: "currency",
        currency: "ZAR",
        minimumFractionDigits: 2
      })
        .format(this.coursesInfo.price)
        .substr(2)}`;
    }
  }

  @Watch("selectedDelegates")
  updAssign() {
    this.updPrice();
    const a = this.courseOverview;
    if (a && a[3]?.content) {
      a[3].content = `${new Intl.NumberFormat("en", {
        style: "currency",
        currency: "ZAR",
        minimumFractionDigits: 2
      })
        .format(this.coursesInfo.price)
        .substr(2)}`;
    }
  }

  updPrice() {
    let sum = 0;
    this.selectedDelegates.forEach((d: any) => {
      this.event.company?.forEach((c: any) => {
        if (d.companyId === c.companyId) {
          sum += +c.price;
        }
      });
    });
    this.event.price = sum / (this.selectedDelegates.length || 1);
  }

  get formattedEventStartDate() {
    return moment(this.event.eventStartDate, "YYYY-MM-DD").format("DD/MM/YYYY");
  }
  formattedEventEndDate() {
    return moment(this.event.eventFinishDate).format("DD/MM/YYYY");
  }

  onDurationSelect() {
    const localDurations: any = this.durations;
    this.courseOverview[1].content = localDurations.find(
      (duration: any) => duration.id === this.event.duration
    ).name;
  }

  async onGoBack() {
    await this.$router.push({ name: "CourseEvents" });
  }
  async onSave() {
    (this.$refs.form as Vue & {
      click: () => void;
    }).click();
  }
  async save() {
    try {
      this.buttonsArray.forEach(btn => {
        btn.disabled = true;
      });
      if (this.event.instructorId === null) {
        this.event.instructorId = "";
      }
      this.event.transportPrice += "";
      const eventData = JSON.parse(JSON.stringify(this.event));
      eventData.eventStartDate = moment(this.event.eventStartDate).format(
        "DD-MM-YYYY"
      );

      eventData.eventableType = this.event.eventType.eventableType;
      eventData.courseDurationId = this.courseDurationId;
      eventData.eventTypeId = this.event.eventType.id;

      if (this.selectedCourseCatId !== 3) {
        delete eventData.duration;
      } else {
        eventData.courseDurationId = eventData.duration;
        delete eventData.duration;
      }
      // eventData.courseDurationId = this.coursesInfo.durationDays || 1;
      // if (this.event.eventTypeId == 2) eventData.courseId = eventData.auditId;
      if (!this.event.price) eventData.price = this.coursesInfo.price;
      if (this.event.eventType.id == 3) eventData.officeTypeId = 3;

      const newEvent: any = await this.createEvent({
        eventData,
        selectedDelegates: this.selectedDelegates
      });
      await this.$success("<strong>Success!</strong> Event has been created!");

      if (this.isOccupationalQualification) {
        const res = await this.$dialog.error({
          text:
            "Do you want to create an Offline Training Schedule For Qualifications. You can do this late on the event edit page",
          title: "Warning",
          persistent: true,
          actions: {
            No: { text: "No" },
            Yes: {
              color: "black",
              text: "Yes I do"
            }
          }
        });
        if (res === "Yes") {
          this.$router.push(`/event/${newEvent.id}/edit`);
        }
      } else if (this.$route.name === "CourseEventsCreate") {
        await this.$router.push({ name: "CourseEvents" });
      }
    } catch (e) {
      (this.$refs.eventForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(e);
      await this.$error(e);
    } finally {
      this.buttonsArray.forEach(btn => {
        btn.disabled = false;
      });
    }
  }
  getCompaniesGoal(data: any) {
    this.event.company = data;
  }
  async handleCourseChange() {
    const eventData: any = this.event;
    await this.getCoursesInfo(eventData.eventableId.toString());
    this.selectedCourseCatId = this.coursesInfo.category.id;
  }
  removeAssign(company: number) {
    const newDelegates: { id: number; companyId: number }[] = [];
    this.selectedDelegates.forEach(
      (d: { id: number; companyId: number }, index: number) => {
        if (d.companyId !== company) {
          this.selectedDelegates.splice(index, 1);
          newDelegates.push(d);
        }
      }
    );
    this.selectedDelegates = newDelegates;
  }
}
